import React, {ReactNode} from "react"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {StaticImage} from "gatsby-plugin-image"
import {Button, Card, Col, Container, Row } from "react-bootstrap";

// @ts-ignore
import appartment from '../img/appartment.svg'
// @ts-ignore
import role from '../img/Page-1.svg'
// @ts-ignore
import piggyBank from '../img/piggy-bank.svg'
import {IPage} from "../../../zant-gorinchem/src/types";
import {graphql, Link} from "gatsby";
import { Project, Media } from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {

    interface Circle {
        icon: string
        children: ReactNode
        subText: string
    }
    const RenderCircle = ({icon, children, subText}: Circle) => {
        return (
            <Card
                style={{
                    boxShadow: '0px 0px 15px #00000029'
                }}
                className={'rounded-circle d-flex justify-content-around py-5 border-0 mx-auto mb-3'}
            >
                <img src={icon} height={40} />
                <div
                    className={'text-primary lead font-weight-bold my-2'}
                >
                    {children}
                </div>
                <small className={'text-uppercase text-secondary'}>{subText}</small>
            </Card>
        )
    }

    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <div
                className={'position-relative text-center'}
            >
                {data.project.hoofdfoto &&
                <>
                    <StaticImage
                        alt="artist impression"
                        src={'../img/ai/Binnengaats HR.jpg'}
                        aspectRatio={2}
                        transformOptions={{
                            cropFocus: "entropy"
                        }}
                        className={'d-none d-md-block'}
                        style={{ maxHeight: 'calc(100vh - 4rem)' }}
                    />
                    <StaticImage
                        alt="artist impression"
                        src={'../img/ai/Binnengaats HR.jpg'}
                        aspectRatio={0.5}
                        transformOptions={{
                            cropFocus: "entropy"
                        }}
                        className={'d-md-none'}
                        style={{ maxHeight: 'calc(100vh - 4rem)' }}
                    />
                </>
                }
                <div
                    className={'position-absolute'}
                    style={{
                        bottom: 0,
                        width: '100%'
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '150px', width: '100%'}}>
                        <path d="M-5.07,104.11 C130.92,219.56 343.68,27.13 500.00,128.78 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}}></path>
                    </svg>
                </div>
                <div
                    className={'d-flex flex-column justify-content-around'}
                    style={{
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, 0%)'
                    }}
                >
                    <div
                        style={{
                            textShadow: '0px 0px 10px #000000',
                            fontWeight: 'bold'
                        }}>
                    <span
                        className={'kalam text-white'}
                        style={{
                            letterSpacing: '1.75px',
                            fontSize: '2rem'
                        }}
                    >
                    De verkoop is gesloten!
                    </span>
                        <div
                            className={'text-white text-uppercase'}
                            style={{
                                letterSpacing: '2.25px',
                                fontSize: '2.5rem'
                            }}
                        >
                            20 fijne starters appartementen
                        </div>
                    </div>
                </div>
            </div>
            <Container style={{
                maxWidth: 800
            }}
            >
                <Row
                    className={'text-center justify-content-center stats'}
                >
                    <Col sm={4}>
                        <RenderCircle icon={appartment} subText={'appartementen'}>
                            20
                        </RenderCircle>
                    </Col>
                    <Col sm={4}>
                        {data.project.project.algemeen.koopaanneemsomVanaf &&
                        <RenderCircle icon={piggyBank} subText={'Vrij op naam'}>
                            v.a. <Prijs prijs={data.project.project.algemeen.koopaanneemsomVanaf}/>
                        </RenderCircle>
                        }
                    </Col>
                    <Col sm={4}>
                        <RenderCircle icon={role} subText={'Woonoppervlakte'}>
                            48m²
                        </RenderCircle>
                    </Col>
                </Row>
            </Container>

            <Container
                fluid={'lg'}
                as={'section'}
                className={'my-5'}>
                <Row>
                    <Col md={5}>
                        <div className={'p-3 d-flex flex-column justify-content-center h-100'}>
                            <h1>Binnengaats - zeewolde</h1>
                            <header>
                                <h2>Zet koers naar stoer en smaakvol wonen</h2>
                            </header>
                            <p
                                className={'lead py-3'}
                                style={{
                                    lineHeight: '36px'
                                }}
                            >
                                Op één van de meest prominente plekken van de nieuwe Schelpenbuurt in Zeewolde komen 20 fantastische startersappartementen in project Binnengaats. Wat een goed begin!
                            </p>
                            <Button
                                as={Link}
                                to={'/appartementen'}
                                size={'lg'}
                                className={'text-uppercase'}
                            >
                                Bekijk de appartementen
                            </Button>
                        </div>
                    </Col>
                    <Col md={7}>
                        <StaticImage
                            src={'../img/ai/Binnengaats AI interieur.jpg'}
                            alt={'interieur'}
                            className={'mb-1 rounded image-shadow'}
                            layout={'fullWidth'}
                        />
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
    project: Project
    media: Media
}

export const pageQuery = graphql`
    fragment Page on MarkdownRemark {
        html
        frontmatter {
            tagline
            title
            description
        }
    }
    query {
        file(name: {eq: "home"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        project {
            project {
                algemeen {
                    koopaanneemsomVanaf
                }
            }
            hoofdfoto {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2)
                    }
                }
            }
        }
    }
`

